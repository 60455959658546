<template>
  <b-container>
    <!-- <h1 class="mb-3">{{ $t('Material de Apoio') }}</h1> -->
    <b-row>
      <b-col v-for="pdf in pdfs" :key="pdf.id" cols="12" md="6" lg="4">
        <b-card>
          <h5>{{ $t(pdf.title) }}</h5>
          <b-card-text>{{ $t(pdf.description) }}</b-card-text>
          <b-button variant="primary" @click="downloadPdf(pdf.src)">{{ $t('Download PDF') }}</b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      pdfs: [
        {
          id: 1,
          title: 'Business Presentation in English',
          description: 'This document presents the main details of the New X Coins business in English.',
          src: ('/marketing/presentation/APN_ingles.pdf')
        },
        {
          id: 2,
          title: 'Présentation commerciale en français',
          description: 'Ce document présente les principaux détails de l\'activité New X Coins en français.',
          src: ('/marketing/presentation/APN_frances.pdf')
        },
        {
          id: 3,
          title: 'Apresentação do Negócio em Português',
          description: 'Este documento apresenta os principais detalhes do negócio New X Coins em português.',
          src: ('/marketing/presentation/APN_portugues.pdf')
        },
        {
          id: 4,
          title: 'Geschäftspräsentation auf Deutsch',
          description: 'Dieses Dokument enthält die wichtigsten Details des New X Coins Geschäfts auf Deutsch.',
          src: ('/marketing/presentation/APN_alemao.pdf')
        }



      ]
    };
  },
  methods: {
    downloadPdf(src) {
      const link = document.createElement('a');
      link.href = src;
      link.download = src.split('/').pop(); // Nome do arquivo baseado no URL do PDF
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<style scoped>
.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
</style>